<template>
  <BaseModal
    name="create-password"
    id="create-password"
    idModal="create-password"
    size="md"
    title="Confirme seu usúario"
    @show="resetFields()"
  >
    <div style="display: grid; gap: 20px">
      <div style="position: relative">
        <b-form-group
          label="Digite a sua senha de usúario"
          label-for="passwordCreate"
        >
          <b-form-input
            id="passwordCreate"
            placeholder="••••••••"
            v-model="password"
            type="password"
            name="passwordCreate"
            v-validate="'required'"
            @focus="isPasswordFocused = true"
            @blur="isPasswordFocused = false"
          ></b-form-input>
          <span class="mostrar-senha" ref="mostrar_senha" @click="mostrarSenha"
            >Mostrar</span
          >
          <b-form-invalid-feedback :state="!errors.has('passwordCreate')">
            Por favor, insira sua senha
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <!-- CONFIRM SENHA -->
      <div style="position: relative">
        <b-form-group
          label="Confirme a sua senha de usúario"
          label-for="confirm_passwordCreate"
        >
          <b-form-input
            id="confirm_passwordCreate"
            placeholder="••••••••"
            v-model="confirm_password"
            type="password"
            name="confirm_passwordCreate"
            v-validate="'required'"
          ></b-form-input>
          <span
            class="mostrar-senha"
            ref="mostrar_confirm_senha"
            @click="mostrarConfirmSenha"
            >Mostrar</span
          >
          <b-form-invalid-feedback
            :state="!errors.has('confirm_passwordCreate')"
          >
            Por favor, insira sua senha
          </b-form-invalid-feedback>
        </b-form-group>
        <div
          v-if="password && confirm_password && password != confirm_password"
          class="container-valid"
        >
          <span class="text-danger">Senhas digitadas não coincidem</span>
        </div>
      </div>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" :disabled="loading" @click="validPassword">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center mt-4" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
import UserService from "@/services/resources/UsersService.js";
const serviceUser = UserService.build();
export default {
  props: ["company_selected"],
  components: {},
  data() {
    return {
      password: "",
      confirm_password: "",
      isPasswordFocused: false,
      showPassword: false,
      showPassword_confirm: false,
      loading: false,
    };
  },
  methods: {
    resetFields(){
      this.password = "";
      this.confirm_password = "";
      this.isPasswordFocused = false;
      this.showPassword = false;
      this.showPassword_confirm = false;
    },
    onSubmit() {
      this.loading = true;
      var all_company = {
        greenn: "greenn",
        club: "club",
        gdigital: "g-digital",
        heaven: "heaven",
      };
      var company = all_company[this.company_selected];
      var data = {
        id: "/login-integration",
        to: "greenn",
        password: this.password,
      };
      serviceUser
        .createId(data)
        .then((resp) => {
          if (resp.ck && resp.ck !== "wrong_password") {
            if (company == "club") {
              window.open(
                `https://adm.${company}.com.br/?rdr=${resp.ck}`,
                "_blank"
              );
            } else if (company == "heaven") {
              window.open(`https://adm.${company}.com.me/${resp.ck}`, "_blank");
            } else {
              window.open(`${process.env.VUE_FRONT_GREENN}?rdr=${resp.ck}&integration=true`, "_blank");
            }
          }
          this.$bvModal.hide("create-password");
        })
        .catch((error) => {
          // console.log(error);
          this.$grToast.toast("Ocorreu um problema, verifique sua senha.", {
            title: "Integrações",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validPassword() {
      if (this.password === "") {
        this.$grToast.toast("Insira sua senha de acesso", {
          title: "Registro",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.confirm_password === "") {
        this.$grToast.toast("Confirme sua senha de acesso", {
          title: "Registro",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.password != this.confirm_password) {
        this.$grToast.toast("As senhas digitadas não coincidem", {
          title: "Registro",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.onSubmit();
    },
    passwordValidationSteps(value) {
      const validations = [
        { step: "Pelo menos 8caracteres", validated: value.length >= 8 },
        { step: "Letra maiúscula", validated: /[A-Z]/.test(value) },
        { step: "Letra minúscula", validated: /[a-z]/.test(value) },
        { step: "Número", validated: /\d/.test(value) },
        { step: "Símbolo", validated: /[@$!%*?&]/.test(value) },
      ];

      return validations;
    },
    mostrarConfirmSenha() {
      const input = document.querySelector("#confirm_passwordCreate");
      this.showPassword_confirm = !this.showPassword_confirm;
      if (this.showPassword_confirm) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_confirm_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_confirm_senha.innerText = "Mostrar";
      }
    },
    mostrarSenha() {
      const input = document.querySelector("#passwordCreate");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = "Mostrar";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.container-valid {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 5px;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 45px;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
</style>
